<template>
    <div>
        <input type="hidden"
                :name="name"
                v-validate="validation"
                v-model="selectedValue"
                :data-vv-as="validationName"
                :data-vv-scope="(scope != null) ? scope : null">
        <multiselect
            :class="classControl"
            v-model="selectedValue"
            :options="options"
            :placeholder="placeholder"
            :showLabels="showLabels"
            :selectLabel="selectLabel"
            :selectGroupLabel="selectGroupLabel"
            :selectedLabel="selectedLabel"
            :deselectLabel="deselectLabel"
            :deselectGroupLabel="deselectGroupLabel"
            :disabled="disabled"
            :customLabel="customLabel"
            :value="valueOption"
            :multiple="multiple"
            :trackBy="trackBy"
            :label="label"
            :searchable="searchable"
            :groupValues="groupValues"
            :groupLabel="groupLabel"
            :groupSelect="groupSelect"
            :internalSearch="internalSearch"
            @search-change="searchChange"
            @select="select"
            @remove="remove"
            :loading="loading"
            :allowEmpty="allowEmpty"
            :close-on-select="isCloseOnSelect"
            :clear-on-select="isClearOnSelect"
        >
            <span slot="noResult"> Tidak ada hasil dari pencarian </span>
            <div v-if="loading" slot="beforeList" class="beforeList">
                <p style="text-align:center;margin-top:10px;">
                    Sedang dalam pencarian
                </p>
            </div>
            <div v-else-if="isShowSearchNote" slot="beforeList" class="beforeList">
                <p style="text-align:center;margin-top:10px;">
                    Ketik Untuk Mencari Lebih Lanjut
                </p>
            </div>
            <template v-if="isNotShowSelectedTags" slot="selection"
                slot-scope="{ values, isOpen }">
                    <span
                    style="background: #fafafa;"
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >
                        {{ values.length }} Item Terpilih
                    </span>
            </template>
            <template v-else slot="selection" slot-scope="props">
                <slot name="selectionTemplate" :data="props"></slot>
            </template>
            <template slot="singleLabel" slot-scope="props">
                <slot name="singleLabelTemplate" :data="props"></slot>
            </template>
            <template slot="option" slot-scope="props">
                <slot name="optionsTemplate" :data="props"></slot>
            </template>
        </multiselect>
         <small class=" kt-font-danger" v-show="errors.first(errorField)">{{errors.first(errorField)}}</small>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    export default {
        inject: ["$validator"],
        components: {
            Multiselect
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [Array, Number, String, Object],
            options: {
                type:Array,
                default:[]
            },
            showLabels:{
                type:Boolean,
                default:true
            },
            selectLabel:{
                type:String,
                default:'Pilih'
            },
            selectGroupLabel:{
                type:String,
                default:'Pilih grup'
            },
            selectedLabel:{
                type:String,
                default:'Terpilih'
            },
            deselectLabel:{
                type:String,
                default:'Batalkan'
            },
            deselectGroupLabel:{
                type:String,
                default:'Batalkan'
            },
            disabled:{
                type:Boolean,
                default:false
            },
            customLabel:{
                type:Function
            },
            valueOption:[Array, Number, String, Object],
            multiple:{
                type:Boolean,
                default:false
            },
            trackBy:{
                type:String
            },
            label:{
                type:String
            },
            searchable:{
                type:Boolean,
                default:true
            },
            groupSelect:{
                type:Boolean,
                default:false
            },
            groupLabel:{
                type:String
            },
            groupValues:{
                type:String
            },
            internalSearch:{
                type:Boolean,
                default: true
            },
            searchChange:{
                type: Function,
                default:() => {}
            },
            select:{
                type: Function,
                default:() => {}
            },
            remove:{
                type: Function,
                default:() => {}
            },
            validation:{
                type: [String, Object],
                default:''
            },
            validationName:{
                type: String,
                default: 'select',
            },
            name:{
                type:String,
                default: 'select'
            },
            scope: {
                type: String,
                default:null
            },
            inputClass:{
                type: String,
                default: ''
            },
            errorClass:{
                type:String,
                default: 'multiselect-error'
            },
            customClass: [String, Array, Function, Object],
            loading:{
                type:Boolean,
                default:false
            },
            placeholder:{
                type:String,
                default:"Tidak ada yang dipilih"
            },
            valueLabel:{
                type: String
            },
            allowEmpty:{
                type: Boolean,
                default: true
            },
            isNotShowSelectedTags: {
                type: Boolean,
                default: false
            },
            isShowSearchNote: {
                type: Boolean,
                default: false
            },
            isCloseOnSelect: {
                type: Boolean,
                default: true
            },
            isClearOnSelect: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            selectedValue: {
                get () {
                   var isValueLabelUndefined = (this.valueLabel == undefined);
                   var isEmptyOrNull = (this.multiple) ? (this.value.length == 0) : (this.value == null);
                   if(isValueLabelUndefined || isEmptyOrNull){
                       return this.value
                   } else {
                        if(this.groupValues == undefined){
                            return (this.multiple) ?
                                this.options.filter(x => this.value.includes(x[this.valueLabel])) :
                                this.options.find(x => x[this.valueLabel] == this.value);
                        } else {
                            var val = (this.multiple) ? [] : null;
                            this.options.forEach(x => {
                                if(this.multiple){
                                    var found = x[this.groupValues].filter(y => this.value.includes(y[this.valueLabel]));
                                    if(found.length > 0) val = [...val,...found];
                                } else {
                                    var found = x[this.groupValues].find(y => y[this.valueLabel] == this.value);
                                    if(found) val = found;
                                }
                            });
                            return val;
                        }
                   }
                },
                set (newValue) {
                    var val = newValue;
                    if(this.valueLabel != undefined) {
                        var isEmptyOrNull = (this.multiple) ? (val.length == 0) : (val == null);
                        if(!isEmptyOrNull){
                            val = (this.multiple) ? newValue.map(x => x[this.valueLabel]) : newValue[this.valueLabel];
                        }
                    }
                    this.$emit('change', val);
                }
            },
            errorField: function(){
                 return (this.scope != null) ? `${this.scope}.${this.name}` : this.name
            },
            classControl: function(){
                if(this.customClass != undefined || this.customClass != null){
                    return this.customClass
                } else {
                    return this.errors.has(this.errorField) ? this.errorClass : this.inputClass;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
