<template>
  <div>
    <div
      id="kt_header"
      class="kt-header kt-grid__item "
      data-ktheader-minimize="on"
    >
      <div
        class="kt-subheader kt-grid__item"
        id="kt_subheader"
      >
        <div class="kt-subheader__main">
          <h3 class="kt-subheader__title">
            {{ baseTitle }}
          </h3>
        </div>
      </div>
      <div class="kt-header__topbar">
        <div class="kt-header__topbar-item">
          <div class="kt-header__topbar-wrapper">
            <span class="kt-font-bolder">Halo, {{ username }}!</span>
          </div>
        </div>
        <div class="kt-header__topbar-item kt-header__topbar-item--user">
          <div
            class="kt-header__topbar-wrapper"
            data-toggle="dropdown"
            data-offset="10px,0px"
          >
            <span class="kt-header__topbar-icon"><i class="fa fa-user-cog"></i></span>
          </div>
          <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-md">
            <div
              class="kt-user-card kt-margin-b-20 kt-margin-b-30-tablet-and-mobile"
              :style="{background: image}"
            >
              <div class="kt-user-card__wrapper">
                <div class="kt-user-card__details">
                  <div class="kt-user-card__name">{{ username }}</div>
                </div>
              </div>
            </div>
            <ul class="kt-nav kt-margin-b-10">
              <!-- <li class="kt-nav__item">
                       <a href="custom_user_profile-v1.html" class="kt-nav__link">
                       <span class="kt-nav__link-icon"><i class="flaticon2-calendar-3"></i></span>
                       <span class="kt-nav__link-text">My Profile</span>
                       </a>
                    </li>
                    <li class="kt-nav__item">
                       <a href="custom_user_profile-v1.html" class="kt-nav__link">
                       <span class="kt-nav__link-icon"><i class="flaticon2-browser-2"></i></span>
                       <span class="kt-nav__link-text">My Tasks</span>
                       </a>
                    </li>
                    <li class="kt-nav__item">
                       <a href="custom_user_profile-v1.html" class="kt-nav__link">
                       <span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
                       <span class="kt-nav__link-text">Messages</span>
                       </a>
                    </li>
                    <li class="kt-nav__item">
                       <a href="custom_user_profile-v1.html" class="kt-nav__link">
                       <span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
                       <span class="kt-nav__link-text">Settings</span>
                       </a>
                    </li> -->
                <li class="kt-nav__item">
                <!-- <router-link
                  class="kt-nav__link"
                  :to="{ name: 'change-password' }"
                > -->
                <a :href="accountProfile"  target="_blank" class="kt-nav__link">
                  <span class="kt-nav__link-icon"><i class="flaticon-user"></i></span>
                  <span class="kt-nav__link-text">Account Setting</span>
                </a>
                <!-- </router-link> -->
              </li>
              <li class="kt-nav__item">
                <!-- <router-link
                  class="kt-nav__link"
                  :to="{ name: 'change-password' }"
                > -->
                <a :href="accountUrl"  target="_blank" class="kt-nav__link">
                  <span class="kt-nav__link-icon"><i class="flaticon-lock"></i></span>
                  <span class="kt-nav__link-text">Ubah Password</span>
                </a>
                <!-- </router-link> -->
              </li>
              <li class="kt-nav__item">
                <a
                  href="#"
                  class="kt-nav__link"
                >
                  <span class="kt-nav__link-icon"><i class="flaticon-clock-1"></i></span>
                  <span class="kt-nav__link-text">{{ dateTime }}</span>
                </a>
              </li>
              <!-- <li class="m-2" v-if="tenants && tenants.length > 1">
                <div class="form-group" style="margin: 20px;">
                      <label for="inputPilihDoctor">Pilih Tenants</label>
                      <select class="form-control" id="exampleFormControlSelect1" v-model="selectedTenant">
                        <option v-for="tenant in tenants" :key="tenant.name" :value="tenant.id">{{tenant.name}}</option>
                      </select>
                </div> -->
                <!-- <label for="exampleFormControlSelect1">select tenant</label>
                <select class="form-control" id="exampleFormControlSelect1" >
                  <option v-for="tenant in tenants" :key="tenant.name" :value="tenant.id">{{tenant.name}}</option>
                  <option>adwdaw</option>
                  <option>1</option>
                </select> -->
                
              <!-- </li> -->
              <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15"> <a
                  href="#"
                  @click="logout()"
                  class="btn btn-outline-metal btn-hover-brand btn-upper btn-font-dark btn-sm btn-bold"
                >Sign Out</a> </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import SweetAlertTemplate from '../_general/SweetAlert'
// const SweetAlert = Vue.extend(SweetAlertTemplate);
import vueMultiSelect from './../_select/VueMultiSelect'
import background from '@/assets/img/head_bg_sm.jpg'
import logOut from '@/services/logOut.service'
import LoginRepository from "@/repositories/LoginRepository";

export default {
  data() {
    return {
      accountUrl: '',
      accountProfile: '',
      dateTime: null,
      tenants:[],
      selectedTenant: {},
      localTenant: {},
      image: `url('${background}')`
    }
  },
  props: {
    baseTitle: { type: String }
  },
  computed: {
    username () {
      return  window.userName ?  window.userName : 'Admin'
    }
  },
  components: {
            vueMultiSelect
  },
  methods: {
    logout() {
     logOut.logout()
    },
    showToastError: function (message) {
      toastr.error(message);
    },
    getLoginData() {
            LoginRepository.getDataSSO().then(_resp => {
               if(_resp.status === 200) {
                const list = _resp.data.filter(_item => !_item.hideOnLogin).map(_item => {
                    return {
                        name: _item.tenant.name ? _item.tenant.name : '-',
                        id: _item.tenant ? _item.tenant.id : '2',
                        alias: _item.alias,
                        enabled: _item.enabled
                    }
                })
                this.tenants = list
                this.selectedTenant = parseInt(window.tenantActive)
                this.localTenant = this.selectedTenant
                // this.username = window.userName
               }
            })
        },
  },
  watch:{
    selectedTenant:  function (val) {
      // if (this.localTenant !== val) {
      //   localStorage.setItem("selectedTent", JSON.stringify(val));
      //   sessionStorage.removeItem('docAppo')
      //   window.location.reload()
      // }
    },
  },
  mounted() {
    const realm = [{
      name: 'keycloak-oidc-puri',
      realm: 'bo-puri'
    }, {
      name: 'keycloak-oidc-karawang',
      realm: 'bo-karawang'
    },
    {
      name: 'keycloak-oidc-mhg',
      realm: 'bo-mhg'
    }]
    const selectedRealmObj = realm.find(_item => _item.name == localStorage.selectedRealm)
    let selectedRealm = ''
    if(selectedRealmObj) {
      selectedRealm = selectedRealmObj.realm
    }
    const url = process.env.VUE_APP_SSO_LINK + '/realms'
    const urlAccount = `${url}/${selectedRealm}/account/password?tenantId=1`
    this.accountProfile = `${url}/${process.env.VUE_APP_SSO_REALM}/account?tenantId=1`
    this.accountUrl = urlAccount
    this.dateTime = moment().format("dddd, D MMMM YYYY hh:mm:ss");
    // this.tenants = JSON.parse(localStorage.getItem("tenants"))
    // this.selectedTenant = JSON.parse(localStorage.getItem("selectedTent"))
    // this.localTenant = this.selectedTenant
    this.getLoginData()
    $('.dropdown-menu').on('click', function(event) {
      event.stopPropagation();
    });
  }
}
</script>
