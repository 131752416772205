import axios from "axios";
const baseDomain = process.env.VUE_APP_API_URL;
const idpLogin = 'realms/Caredokter/caredokter-resources/idp-login'
const login = `${baseDomain}/auth/login`;

export default {
    login(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${login}`, data)
                .then(_resp => {
                    resolve(_resp.data)
                })
                .catch(err => reject(err.response))
        })
    },
    getDataSSO() {
        return new Promise((resolve, reject) => {
            const token = localStorage.tokens
            axios.get(`${process.env.VUE_APP_SSO_LINK}/${idpLogin}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(_resp => {
                resolve(_resp)
            }).catch(err => reject(err.response))
        })
    }
}